/** @jsxImportSource @emotion/react */
import { Theme, ThemeProvider } from "@mui/material/styles";
import { useRef } from "react";
import { darkTheme } from "../../layouts/theme";
import MabiParallax from "../MabiloftSmoothScrollInterpolator";

//@ts-ignore
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface DarkSideProps {
  children?: React.ReactNode;
}

const DarkSide = (props: DarkSideProps) => {
  const { children } = props;

  const backgroundRef = useRef<HTMLDivElement>(null);

  return (
    // <StyledEngineProvider injectFirst>
    <ThemeProvider theme={darkTheme}>
      <div
        css={{
          color: "rgba(255, 255, 255, 0.6)",
          a: {
            color: "rgba(255, 255, 255, 0.6)",
            textDecoration: "none",
          },
          position: "relative",
          backgroundColor: "#151c34",
          overflow: "hidden",
        }}
      >
        <div
          ref={backgroundRef}
          css={{
            width: "100%",
            height: "200%",
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "hidden",
          }}
        >
          <MabiParallax
            id={"footerGradientInterpolator"}
            stepper={0.3}
            duration={1.9}
            tweens={[
              {
                selector: ".footerLinearGradient",
                from: { y: 0 },
                // to: { y: -(backgroundRef.current?.offsetHeight ?? 2000) / 2 },
                to: { y: -600 },
              },
            ]}
          >
            <div
              className="footerLinearGradient"
              css={{
                backgroundColor: "#151c34",
                backgroundPosition: "center",
                backgroundRepeat: "repeat",
                backgroundSize: "cover",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 0,
                willChange: "transform",
                // backgroundAttachment: 'fixed',
                backgroundImage: `repeating-linear-gradient(
                                    to bottom,
                                    transparent,
                                    transparent 7px,
                                    #151c34 7px,
                                    #151c34 100px
                                    ),
                                    repeating-linear-gradient(
                                        to right,
                                        #1e2749,
                                        #1e2749 7px,
                                        #151c34 7px,
                                        #151c34 100px
                                        )`,
              }}
            />
          </MabiParallax>
        </div>
        <div css={{ position: "relative", zIndex: 1 }}>{children}</div>
      </div>
    </ThemeProvider>
    // </StyledEngineProvider>
  );
};

export default DarkSide;
